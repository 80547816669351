import React from 'react';
import '../Login/Login.scss';
import './CreateAccount.scss';
import '../../resources/main.scss';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { clientsService } from '../../services/ClientsService';
import { withRouter, Link } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import MetaTags from 'react-meta-tags';
import LoginOptionsModal from "../../components/LoginOptionsModal/LoginOptionsModal";

class CreateAccount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newAccount: {
                name: '',
                surname: '',
                weddingDate: '',
                city: '',
                email: '',
                password: '',
                confirmPassword: '',
                phone: '',
                discountCode: '',
                acceptGDPR: false,
                acceptTerms: false,
                amount: 99
            },
            showPassword: false,
            clientCreateStarted: false,
            paymentUrl: '',
            newClient: false,
            isLoginModalOpen: false
        }
        this.notificationSystem = React.createRef();
    }

    handleChange = event => {
        const field = event.target.name;
        const newAccount = { ...this.state.newAccount };
        newAccount[field] = event.target.value;
        this.setState({ newAccount })
    }
    handleGDPRAccept = () => {
        const newAccount = { ...this.state.newAccount };
        newAccount.acceptGDPR = !newAccount.acceptGDPR;
        this.setState({ newAccount })
    }
    handleTermsAccept = () => {
        const newAccount = { ...this.state.newAccount };
        newAccount.acceptTerms = !newAccount.acceptTerms;
        this.setState({ newAccount })
    }

    createAccount = event => {
        event.preventDefault();
        const notification = this.notificationSystem.current;

        if (!this.state.newAccount.acceptGDPR) {
            notification.addNotification({
                message: 'Vă rugăm să acceptați termenii GDPR.',
                level: 'error',
                position: 'tc'
            });

            return;
        }

        if (!this.state.newAccount.acceptTerms) {
            notification.addNotification({
                message: 'Vă rugăm să acceptați termenii și condițiile FacemNuntă.',
                level: 'error',
                position: 'tc'
            });

            return;
        }

        if (this.state.newAccount.password !== this.state.newAccount.confirmPassword) {
            notification.addNotification({
                message: 'Parola introdusă nu coincide cu parola confirmată.',
                level: 'error',
                position: 'tc'
            });

            return;
        }
        if (this.state.newAccount.password.length<6) {
            notification.addNotification({
                message: 'Parola trebuie să aibă minim 6 caractere',
                level: 'error',
                position: 'tc'
            });

            return;
        }

        this.setState({ clientCreateStarted: true });

        clientsService.createAccount(this.state.newAccount)
            .then(response => {
                notification.addNotification({
                    message: 'Felicitari! Contul tau a fost creat cu success, Platiti sau logati-va gratuit pentru 7 zile sa incercati platforma.',
                    level: 'success',
                    position: 'tc'
                });

                this.setState({ clientCreateStarted: false });
                this.setState({ newClient: true });
                this.openLoginModal()

                if (response.data.payment_url) {
                    this.setState({ paymentUrl: response.data.payment_url });
                } else {
                    notification.addNotification({
                        message: 'A aparut o problema la generarea platii, va rugam sa ne contactati si vom rezolva problema.',
                        level: 'error',
                        position: 'tc'
                    });
                }
            })
            .catch(error => {
                this.setState({ clientCreateStarted: false });

                if (error.response.data.client_already_exists) {
                    notification.addNotification({
                        message: 'Un client cu acest email este deja inregistrat in baza noastra de date. Va rugam sa va logati pe pagina principala.',
                        level: 'error',
                        position: 'tc'
                    });

                    return;
                }

                notification.addNotification({
                    message: 'A aparut o problema la crearea contului, va rugam sa ne contactati si vom rezolva problema.',
                    level: 'error',
                    position: 'tc'
                });
            });
    }
    openLoginModal = () => {
        this.setState({ isLoginModalOpen: true })
    }
    closeModal = () => {
        this.setState({ isLoginModalOpen: false })
    }
    togglePassword = () => {
        console.log('here')
        let showPassword = this.state.showPassword;
        showPassword = !showPassword;
        this.setState({ showPassword })
    }
    render() {
        return (
            <LoadingOverlay
                active={this.state.clientCreateStarted}
                spinner
                text='Contul dumneavoastra se creeaza...'
            >
                <div className='standard login account'>
                    <Header menuItem='creaza-cont' />
                    <MetaTags>
                        <title>Facem Nunta</title>
                        <meta
                            name="description"
                            content="Creaza un cont si incepe planificarea nuntii tale. Inca un pas pana la organizarea nuntii pe care ti-o doresti!"
                        />
                        <meta
                            name="keywords"
                            content="facem nunta, nunta, organizare nunta, planificare nunta, invitatii nunta, invitatii nunta online, rsvp invitatie, plan nunta, agenta miresei, agenda nunta, aplicatie nunta, aplicatie online nunta, invitati nunta, gestiune lista invitati nunta, aranjare mese, album foto online nunta, cont"
                        />
                        <meta
                            property="og:title"
                            content="Facem Nunta"
                        />
                        <meta
                            property="og:url"
                            content="https://www.facemnunta.ro/app/creeaza-cont"
                        />
                    </MetaTags>
                    <div className='login__wrapper'>
                        <img src='/images/lateral.png' alt='background' className='login__lateral account__lateral' />
                        <div className='login__container account__container'>
                            <h3 className='login__title'>Creează cont</h3>
                            <h4 className='login__subtitle'>Ai deja un cont? <Link className="standard__link" to="/app/login"><span>Conectează-te</span></Link></h4>
                            <form className='login__form' onSubmit={this.createAccount}>
                                <div className='account__couple'>
                                    <div>
                                        <h4 className='login__label'>Nume*</h4>
                                        <input
                                            className='login__input'
                                            type="text"
                                            name="name"
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.newAccount.name} />
                                    </div>
                                    <div>
                                        <h4 className='login__label'>Prenume*</h4>
                                        <input
                                            className='login__input'
                                            type="text"
                                            name="surname"
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.newAccount.surname} /></div></div>
                                <div className='account__couple'>
                                    <div>
                                        <h4 className='login__label'>Data nunții*</h4>
                                        <input
                                            className='login__input'
                                            type="date"
                                            name="weddingDate"
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.newAccount.weddingDate} />
                                    </div>
                                    <div>
                                        <h4 className='login__label'>Oraș Eveniment*</h4>
                                        <input
                                            className='login__input'
                                            type="text"
                                            name="city"
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.newAccount.city} />
                                    </div>
                                </div>
                                <div className='account__couple'>
                                    <div>
                                        <h4 className='login__label'>Telefon*</h4>
                                        <input
                                            className='login__input'
                                            type="number"
                                            name="phone"
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.newAccount.phone} />
                                    </div>
                                    <div>
                                        <h4 className='login__label'>Email*</h4>
                                        <input
                                            className='login__input'
                                            type="email"
                                            name="email"
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.newAccount.email} />
                                    </div>
                                </div>
                                <div className='account__couple'>
                                    <div className='account__password-box'>

                                        <h4 className='login__label'>Parolă*</h4>
                                        <input
                                            className='login__input'
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            name="password"
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.newAccount.password} />

                                        <img src='/images/eye.png' alt='see password' className='account__eye' onClick={() => this.togglePassword()} />

                                    </div>
                                    <div className='account__password-box'>
                                        <h4 className='login__label'>Confirmare parolă*</h4>
                                        <input
                                            className='login__input'
                                            type={this.state.showPassword ? 'text' : 'password'}
                                            name="confirmPassword"
                                            onChange={this.handleChange}
                                            required
                                            value={this.state.newAccount.confirmPassword} />
                                        <img src='/images/eye.png' alt='see password' className='account__eye' onClick={() => this.togglePassword()} />
                                    </div>

                                </div>
                                <div className='account__couple'>
                                    <div>
                                        <h4 className='login__label'>Cod Discount</h4>
                                        <input
                                            className='login__input'
                                            type="text"
                                            name="discountCode"
                                            onChange={this.handleChange}
                                            value={this.state.newAccount.discountCode} />
                                    </div>
                                </div>
                                <div className='account__checkbox-div' onChange={this.handleGDPRAccept}>
                                    <label className='account__checkbox-label'>
                                        <input type="checkbox" className='account__checkbox' />
                                        <span className='account__checkbox-custom'></span>
                                    </label>
                                    <h4>Sunt de acord ca datele mele să fie procesate conform GDPR.</h4>
                                </div>
                                <div className='account__checkbox-div' onChange={this.handleTermsAccept}>
                                    <label className='account__checkbox-label'>
                                        <input type="checkbox" className='account__checkbox' />
                                        <span className='account__checkbox-custom'></span>
                                    </label>
                                    <h4>Prin conectare, sunt de acord cu Termenii și condițiile echipei Facem Nuntă.</h4>
                                </div>
                                <div className='login__account-box'>
                                    <button type='submit' className='login__submit-button'><h5 className='login__button-writing'>CREARE CONT</h5></button>
                                    {/* <div className='login__option-box'>
                                    <div className='login__line' />
                                    <h4 className='login__option-writing'>Sau înregistrează-te cu</h4>
                                    <div className='login__line' />
                                </div>
                                <button className='login__option-login'>
                                    <img src='/images/google.png' alt='google' />
                                    <h5>Google</h5>
                                </button>
                                <button className='login__option-login' disabled>
                                    <img src='/images/facebook.png' alt='facebook' />
                                    <h5>Facebook</h5>
                                </button> */}

                                </div>
                            </form>

                        </div>
                    </div>
                    <Footer />
                    <NotificationSystem
                        ref={this.notificationSystem}
                        style={NotificationSystemStyle}
                    />
                    {this.state.isLoginModalOpen && <LoginOptionsModal
                        modalIsOpen={this.state.isLoginModalOpen}
                        closeModal={this.closeModal}
                        paymentUrl={this.state.paymentUrl}
                    />}
                </div>
            </LoadingOverlay>

        )
    }
}

export default withRouter(CreateAccount);
