import React from 'react';
import '../../resources/main.scss';
import Modal from 'react-modal';
import { invitationService } from "../../services/InvitationService";
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import '../AddEventPartModal/Add.scss'
import './AddGuest.scss'
import ReactTooltip from 'react-tooltip';

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    borderRadius: "32px"
  }
};

class AddGuestModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      guest: {
        name: '',
        sent_invitation: "",
        participant: false,
        category:''
      },
      categories: [],
      currentGuest: this.props.guest,
      websiteEventId: this.props.websiteDetailsId
    };

    this.notificationSystem = React.createRef();
  }
  componentDidMount() {
    if (this.props.guest) {
      let guest = { ...this.state.guest };
      guest = {
        ...this.props.guest,
      };

      this.setState({ guest });
    }
    invitationService.getCategories(this.state.websiteEventId).then(response => {
      let categories = [...this.state.categories];
      categories = response.data.categories;
      let guest = { ...this.state.guest }
      if (!guest.category) {
        guest.category = categories[0].name;
        this.setState({ guest })
      }

      this.setState({ categories })
    })
      .catch(err => console.log(err))
  }

  handleChange = event => {
    const field = event.target.name;
    const guest = { ...this.state.guest };
    guest[field] = event.target.value;
    this.setState({ guest });
  }

  handleCheckboxChange = event => {
    const field = event.target.name;
    const guest = { ...this.state.guest };
    guest[field] = event.target.checked;
    this.setState({ guest });
  }

  handleAddInvitation = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;

    invitationService.createOrUpdateInvitation(this.state.guest, this.state.websiteEventId)
      .then(response => {
        this.props.updateGuestList(response.data);
        setTimeout(() => {
          this.props.closeModal()
       }, 1000);
      })

      .catch(error => {
        console.log(error);
      })
  }
  updateGuestType = guestTypeValue => {
    const guest = { ...this.state.guest };
    guest.participant = guestTypeValue;
    this.setState({
      guest
    })

  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          style={customStyles}
          contentLabel="modal Data"
          ariaHideApp={false}
        >
          <div className='standard add-modal add-guest'>
            <img src='/images/close.png' className=' success__close-icon' alt='close' onClick={this.props.closeModal} />
            <h2 className="add-modal__title">{this.state.currentGuest ? 'Editeaza' : 'Adauga'} invitat</h2>
            <form onSubmit={this.handleAddInvitation}>
              {/* <div className="guests-types">
                <label className={this.state.guest.participant ? "guest-type" : "guest-type-picked"}>
                  <img data-tip='Invitat' data-event='mouseenter' src='/images/guest.png' alt='guest' className='guest__guest-type-icon' />
                  <h5 className="guest-label">Prieten</h5>
                  <input type="radio" checked={this.state.guest.participant === false} name="guest" onChange={() => this.updateGuestType(false)} />
                  <span className="guest__checkmark"></span>
                  <div className='admin__additional-info'>
                    <ReactTooltip globalEventOff='click' />
                  </div>
                </label>
                <label className={this.state.guest.participant ? "guest-type guest-type-picked" : "guest-type"}>
                  <img data-tip='De exemplu fotograf sau dj, persoană căreia trebuie să-i asiguri meniul' data-event='mouseenter' src='/images/auxiliar.png' alt='guest' className='guest__guest-type-icon' />
                  <h5 className="guest-label">Persoană auxiliară</h5>
                  <input type="radio" checked={this.state.guest.participant === true} name="auxiliar" onChange={() => this.updateGuestType(true)} />
                  <span className="guest__checkmark"></span>
                  <div className='admin__additional-info'>
                    <ReactTooltip globalEventOff='click' />
                  </div>
                </label>

              </div> */}
              <div>
                <h4 className='add-modal__label'>Nume</h4>
                <input
                  className='add-modal__field'
                  type="text"
                  name="name"
                  onChange={this.handleChange}
                  required
                  value={this.state.guest.name} />
              </div>
              <div>
                <h4 className='add-modal__label'>Categorie invitat</h4>
                <select
                  className='add-modal__field'
                  name="category"
                  onChange={this.handleChange}
                  value={this.state.guest.category}
                >
                  <option value="">Selecteaza categoria</option>
                  {this.state.categories.map(category => (
                    <option key={category.id} value={category.name}>{category.name}</option>
                  ))}
                </select>
              </div>

              <div className="add-modal__toggle-consent">
                <h4 className='add-modal__label'>Invitație trimisă</h4>
                <label className="switch">
                  <input type="checkbox"
                    name="sent_invitation"
                    onChange={this.handleCheckboxChange}
                    checked={this.state.guest.sent_invitation} />
                  <span className="slider round"></span>
                </label>
              </div>

              <div className="add-modal__buttons">
                <button type='submit' className='add-modal__button add-modal__submit'>{this.props.guest ? 'Editează' : 'Adaugă'}</button>
                <button type='submit' className='add-modal__button add-modal__cancel' onClick={this.props.closeModal}>Închide</button>
              </div>
            </form>
          </div>
        </Modal>
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default AddGuestModal;
